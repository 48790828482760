import React from 'react';
import { Handle,Position } from 'reactflow';
import Skin from './McCharacter.webp'



export default function Me() {

    const parentStyle = {
        width:'90px',
        height: '170px',
        background:'white',
        padding:'0px 10px',
        borderRadius:'10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'relative',
        paddingBottom:'30px'
    }
    const imgStyle = {
        width:'80%'
    }
    const label = {
        width:'70px',
        height:'20px',
        position:'absolute',
        top:'80%',left:'50%',
        fontSize:'10px'
        ,display:'flex',
        justifyContent:'center',
        alignItems:'center',
        transform:'translate(-50%)',
        color:'black'
    }

  return (
    <>
        <div style={parentStyle}>
            <img style={imgStyle} src={Skin} alt="ArashKH" />
        </div>
        <div style={label}>ArashKH :D</div>
        <Handle type="source" position={Position.Right} />
    </>
  );
}