import React from "react";
import { Component } from "react";
import './contact.css'
import ContactNode from './ContactNode'
import ReactFlow, { Background } from 'reactflow';
import Marquee from "react-fast-marquee";
import Footer from "./Footer/footer";



export default class Contact extends Component{
    constructor(){
        super()
        this.state = {
            marqStyle:{
                height:'10vh',
                fontSize:'30px',
                borderTop:'2px solid white',
                boderBottom:'2px solid white',
                position:'absolute',
                top:'0px'
            },
            nodeTypes:{ContactNode:ContactNode},
            nodes:[
                {
                    id:'title',
                    position:{x:-30,y:50},
                    type:'input',
                    data:{
                        label:'Socials'
                    },
                    sourcePosition:'right'
                    ,style:{
                        borderRadius:'100px'
                        ,boxShadow:'4px 4px 0px'
                    }
                },
                {
                    id:'email',
                    position:{x:150,y:-50},
                    type:'ContactNode',
                    data:{
                        link:(<a target='_blank' href="mailto:contact@arashkh.com"><i class="bi bi-envelope-fill"></i> contact@arashkh.com</a>)
                    },
                },
                {
                    id:'telegram',
                    position:{x:200,y:0},
                    type:'ContactNode',
                    data:{
                        link:(<a href="https://t.me/Arashkh_web"><i className="bi bi-telegram"></i> Arashkh_web</a>)
                    },
                },
                {
                    id:'linkedin',
                    position:{x:200,y:100},
                    type:'ContactNode',
                    data:{
                        link:(<a href="https://www.linkedin.com/in/arash-kheirollahi/"><i className="bi bi-linkedin"></i> Arash Kheirollahi</a>)
                    },
                },
                {
                    id:'instagram',
                    position:{x:150,y:150},
                    type:'ContactNode',
                    data:{
                        link:(<a href="https://www.instagram.com/arashkh_web/"><i className="bi bi-instagram"></i> Arashkh_web</a>)
                    },
                }
            ],
            edges:[
                {
                    id:'title-email',
                    source:'title',
                    target:'email',
                    animated: true,
                    style: { stroke: 'black' },
                },
                {
                    id:'title-telegram',
                    source:'title',
                    target:'telegram',
                    animated: true,
                    style: { stroke: 'black' },
                },
                {
                    id:'title-linkedin',
                    source:'title',
                    target:'linkedin',
                    animated: true,
                    style: { stroke: 'black' },
                },
                {
                    id:'title-instagram',
                    source:'title',
                    target:'instagram',
                    animated: true,
                    style: { stroke: 'black' },
                },

            ]
        }
    }
    componentDidMount(){

    }
    render(){
        return(
            <>

            
                <div id="Contact" className="Section">
            <Marquee 
                    style={this.state.marqStyle}
                    speed={50}
                    gradient={false}
                    >
                    Contact <div className={'dot'}></div>
                    联系 <div className={'dot'}></div>
                    contact <div className={'dot'}></div>
                    контакт<div className={'dot'}></div>
                    İletişim <div className={'dot'}></div>
                    </Marquee>
                    <div className="title h1">Let's get Social</div>
                    <div className="ContactParent">
                        <ReactFlow 
                        nodes={this.state.nodes} 
                        edges={this.state.edges}
                        nodeTypes={this.state.nodeTypes}
                        fitView
                        zoomOnScroll={false}
                        panOnDrag={false}
                        style={
                            {cursor:'default'}
                            
                        }
                        >
                        <Background />
                        </ReactFlow>
                    </div>
                        <Footer></Footer>
                </div>
            </>
        )
    }
}

{/* <li><a href=""></a><i className="bi bi-envelope-at-fill"></i> contact@arashkh.com</li>
 <><a href=""></a><
 <><a href=""></a><
 <><a href=""></a>< */}