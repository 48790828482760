import React from "react";
import { Component } from "react";
import './start.css'

export default class Start extends Component{
    constructor(){
        super()
        this.state={
            logo: (<i className="bi bi-balloon-heart-fill"></i>),
            name: 'ArashKH | FrontEnd'
        }
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                logo:'',
                name:''
            })
        }, 2700);
    }


    render(){
        return(
            <>
                <div id="StartParent">
                    <div className="logo">{this.state.logo}</div>
                    <div className="name">{this.state.name}</div>
                </div>
            </>
        )
    }
}