import React from "react";
import { Component } from "react";
import './home.css'
import Mepic from './asset/me.webp'

export default class Home extends Component{
    constructor(){
        super()
        this.state = {

        }
    }
    componentDidMount(){

    }
    render(){
        return(
            <>
                <div id="Home" className="Section">
                    <div className="Homeleft">
                        <div id="greetings">
                            <div className="hi">Hi👋
                                <div className="im">i'm
                                    <div className="arash">Arash
                                        <div className="kh">Kheirollahi </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nice">nice to meet you :D</div>
                        </div>
                    </div>
                    <div className="Homeright">
                        <div className="CircleContainer" style={{
                            backgroundImage:'url('+Mepic+')'
                            ,backgroundSize:'contain'
                        }}>
                            <figure>
                                <img className="d-none" src={Mepic} alt="ArashKH" />
                            </figure>
                            <div className="littleMoon"></div>
                            <div className="littleMoon"></div>
                            <div className="littleMoon"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}