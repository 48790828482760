import { useCallback } from 'react';
import { Handle,Position } from 'reactflow';



export default function ProgNode({ data }) {

    const parentStyle = {
        width:'fit-content',
        height: '30px',
        background:'white',
        border:'solid 1px rgb(24, 24, 24)',
        padding:'0px 10px',
        borderRadius:'20px',
        display:'flex',
        alignItems:'center'
        ,justifyContent:'center'
        ,backGround:'rgb(211, 211, 211)',
        boxShadow:'2px 2px 0px'
    }

  return (
    <>
        <Handle type="target" position={Position.Left} />
        <div style={parentStyle}>
            <div>{data.link}</div>
        </div>
    </>
  );
}