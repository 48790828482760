import React, { Component } from "react";
import './workComp.css'

export default class work extends Component{
    constructor(){
        super()
        this.state={
            icons:[
                (<i class="bi bi-box-arrow-in-right"></i>),
                (<i class="bi bi-github"></i>)
                ]
        }
    }

    

    render(){
        return(
            <>
                <div className="workStory">
                    <div className="box">
                        <div className="line">
                        <a title="Open Live server" target='_blank' href={this.props.links[0]}><h2 className="title">{this.props.title}</h2></a>
                        </div>
                        
                    </div>
                    <br />
                    <div className="info">
                        <p>{this.props.desc}</p>
                        <figure>
                            <img src={this.props.thumbnail} alt={'Arashkh | ' + this.props.title} />
                        </figure>
                        <div className="links">
                            {this.props.links.map((element,index)=>{
                                if(element != ''){
                                    return(
                                        <>
                                            <a target='_blank' href={element}>{this.state.icons[index]}</a>
                                        </>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

