import { useCallback } from 'react';
import { Handle,Position } from 'reactflow';
import ProgressBar from 'react-dennis-progressbar';



export default function ProgNode({ data }) {

    const parentStyle = {
        width:'100px',
        height: '27px',
        background:'white',
        padding:'0px 10px',
        borderRadius:'20px'
    }

  return (
    <>
        {/* <Handle type="target"  /> */}
        <div style={parentStyle}>
            <div></div>
        <ProgressBar stepNumber={data.percent} 
        steps={[10]} 
        bullets={false} 
        lineHeight={8}
        lineColor={{
            active: data.color[0],
            inactive: data.color[1]
          }} />

        </div>
    </>
  );
}