import React from "react";
import { Component } from "react";
import './headerStyle.css'

export default class Header extends Component{
    constructor(){
        super()
        this.state = {

        }
    }
    componentDidMount(){

    }
    render(){
        return(
            <>
                <header>
                    <div className="icon"><i class="bi bi-balloon-heart-fill"></i></div>
                    <ul>
                        <li><a href="#Home">Home</a></li>
                        <li><a href="#Me">Me</a></li>
                        <li><a href="#Works">Works</a></li>
                        <li><a href="#Contact">Contact</a></li>
                    </ul>
                    <div id="titleBubble">
                        
                    </div>
                </header>
            </>
        )
    }
}