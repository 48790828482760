import React, { Component } from "react";
import './footer.css'

export default class footer extends Component{
    constructor(){
        super()
        this.state={
            style:{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                fontSize:'1.5',
                background:'#2b2b2b',
                width:'100%',
                height:'50px',
                position:'absolute',
                bottom:'0px'
            }
        }
    }

    render(){
        return(
            <>
                <div className="footer" style={this.state.style}>
                    Designed and developed by <strong>&nbsp; Arash Kheirollahi &nbsp;</strong> <i className="bi bi-dot"></i> Powered by &nbsp;<a href="https://reactjs.org/">ReactJs</a> <i className="bi bi-dot"></i> 
                    <a href="https://github.com/ArashkKH"><i className="bi bi-github"></i></a>
                </div>
            </>
        )
    }
}