import React from "react";
import { Component } from "react";
import './works.css'
import WorkComp from './workComp'

import ScNike from './Asset/NIke.jpg'
import ScAkig from './Asset/AKIG.jpg'
import ScApex from './Asset/Apex.jpg'
import ScOneroom from './Asset/OneroomGalley.jpg'
import ScSolar from './Asset/SolarSystem.jpg'
import ScApple from './Asset/Apple.jpg'
import ScArash1 from './Asset/Arashkh1.0.jpg'
import ScBrand from './Asset/BrandStorm.jpg'
import ScTictac from './Asset/Tic-Tac-Toe.jpg'
import ScNumper from './Asset/Num-to-per.jpg'
import Marquee from "react-fast-marquee";



export default class Works extends Component{
    constructor(){
        super()
        this.state = {
            marqStyle:{
                height:'10vh',
                fontSize:'30px',
                borderTop:'2px solid white',
                boderBottom:'2px solid white'
            },
            works:[
                {
                    id : 28001,
                    title:'Nike',
                    status:'Html & Learning CSS',
                    desc:'I started with this little project at first, a simple and little shoe showcase',
                    links:['https://arashkh.com/Projects/Nike/Welcom%20page/',''],
                    thumbnail:ScNike,
                },{
                    id : 28002,
                    title:'AKIG',
                    status:'Learning CSS',
                    desc:'I kept exploring the world of css and learnt new things along the path',
                    links:['https://www.arashkh.com/Projects/AKIG/AKIG',''],
                    thumbnail:ScAkig,
                }
                ,{
                    id : 28003,
                    title:'Apex Legends webpage',
                    status:'Learning CSS',
                    desc:'This is one of my favorite early works, a concept that i really admire and wanted to try making a fanPage about it',
                    links:['https://www.arashkh.com/Projects/ApexLegends','https://github.com/ArashkKH/ApexLegends'],
                    thumbnail:ScApex,
                }
                ,{
                    id : 28004,
                    title:'OneRoom Gallery',
                    status:'Learning CSS',
                    desc:'After this Project I started to get a really good feedback and love from people supporting me, This was new for me and i loved it',
                    links:['https://www.arashkh.com/Projects/OneRoomGallery','https://github.com/ArashkKH/One-room-Gallery'],
                    thumbnail:ScOneroom,
                }
                ,{
                    id : 28005,
                    title:'Solar System',
                    status:'Learning CSS',
                    desc:'So i learned about animations and kept wondering about infinite ones; and speaking of infinity, lets take a look at the sky!',
                    links:['https://www.arashkh.com/Projects/SolarSystem','https://github.com/ArashkKH/Solar-System'],
                    thumbnail:ScSolar,
                }
                ,{
                    id : 28006,
                    title:'Iphone 13 3D',
                    status:'Experimenting Scss and Responsive styling',
                    desc:'Sass and Scss were a blast for me, The beginning of working with programs main elements like loops and function, But inside cascading style sheet? I was amazed and created another 3D project',
                    links:['https://www.arashkh.com/Projects/Iphone','https://github.com/ArashkKH/Iphone13'],
                    thumbnail:ScApple,
                }
                ,{
                    id : 28007,
                    title:'ArashKh Website',
                    status:'Working with Host and domains',
                    desc:'What is a frontEnd developer without a website :/  So i thought why not? \n and created my first ever personal website (Without any Js till now)',
                    links:['https://www.arashkh.com/Projects/Arashkh1.0',''],
                    thumbnail:ScArash1,
                }
                ,{
                    id : 28008,
                    title:'BrandStorm',
                    status:'Working with JS and PWAs',
                    desc:'And I just relized im at the tip of the iceberg, My journey through the path of this language had been started. \n so I Created an Awsome Web application with my friend Zahra! (Check out her Website here!)',
                    links:['https://www.arashkh.com/Projects/BrandStorm','https://github.com/ArashkKH/BrandStorming'],
                    thumbnail:ScBrand,
                }
                ,{
                    id : 28009,
                    title:'Tic-Tac-Toe!',
                    status:'Trying to make a worthy opponent with JS',
                    desc:'So there was this challeng to make a game, but whats the point in playing without competition \n And then there was my beutiful and simple Tic-Tac-Toe AI player, He is good though; Maybe try and beat him. Ill wait >:)',
                    links:['https://www.arashkh.com/Projects/tictactoe','https://github.com/ArashkKH/AK-Tic-Tac-Toe'],
                    thumbnail:ScTictac,
                }
                ,{
                    id : 28010,
                    title:'Number to String converter',
                    status:'First Project with ReactJs',
                    desc:'To start and get the hang of react i tried to make this converter, Simple and tidy✨',
                    links:['https://www.arashkh.com/Projects/num-to-per','https://github.com/ArashkKH/num-to-text-persian-'],
                    thumbnail:ScNumper,
                }
            ]
        }
    }
    componentDidMount(){

    }
    render(){
        return(
            <>
               <div id="Works" className="Section">
                <Marquee 
                    style={this.state.marqStyle}
                    speed={50}
                    gradient={false}
                    >
                    Creations <div className={'dot'}></div>
                    创建 <div className={'dot'}></div>
                    creatie <div className={'dot'}></div>
                    создание<div className={'dot'}></div>
                    oluşturma <div className={'dot'}></div>
                </Marquee>
                    <div id="WorksParent">
                        <div className="workChart">
                                {this.state.works.map((element)=>{
                                    return(
                                        <WorkComp
                                        key={element.id}
                                        title={element.title}
                                        aim={element.status}
                                        desc={element.desc}
                                        links={element.links}
                                        thumbnail={element.thumbnail}
                                        ></WorkComp>
                                    )
                                })}
                        </div>
                    </div>
                </div> 
            </>
        )
    }
}