import React from "react";
import { Component } from "react";
import './skills.css'
import ReactFlow, { Background, Controls } from 'reactflow';
import './../../../node_modules/reactflow/dist/style.css'
import ProgNode from './ProgNodes'
import ProgNodeD from './ProgNodeDown'
import MeNode from './me'
import Marquee from "react-fast-marquee";




export default class Skills extends Component{
    constructor(){
        super()
        this.state = {
            
            nodes:[
                {
                    id:'me',
                    type:'meNode',
                    position:{x:0,y:-20},
                    sourcePosition: 'right',
                },
                
                {
                    id:'edu',
                    position:{x:140,y:-50},
                    data:{label:'🎓 Education'},
                    targetPosition:"left",
                    sourcePosition:'right'
                    ,style:{
                        borderRadius:'10px'
                    }
                },
                {
                    id:'maj',
                    style:{
                        borderWidth:'2px',
                        borderColor:'navy'
                    },
                    position:{x:320,y:-100},
                    data:{label:'💻 Sofware Engineering'},
                    targetPosition:'left'
                    ,style:{
                        padding:'10px 5px',
                        borderRadius:'10px'
                    },
                    
                    
                },
                {
                    id:'azad',
                    position:{x:420,y:-50},
                    data:{label:'Tehran-Azad Univ.'}
                    ,style:{
                        width:'120px',
                        fontSize:'10px'
                        ,padding:'5px'
                        
                    }
                    ,targetPosition:'left'
                    ,type:'output'
                },
                {
                    id:'other',
                    position:{x:180,y:20},
                    data:{label:'🍕 Others'}
                    ,targetPosition:'left'
                    ,sourcePosition:'right',
                    style:{
                        borderRadius:'10px'
                    }
                },
                {
                    id:'photo',
                    position:{x:350,y:130},
                    data:{label:'📷 Photography'},
                    style:{
                        transform:'translate(350px,130px) scale(0.7) ',
                        // background:'#ededed',
                        borderRadius:'20px'
                    }
                    ,targetPosition:'left'
                },
                {
                    id:'photoLv',
                    position:{x:395,y:150},
                    type:'progNode',
                    data:{
                        percent:8,
                        color:['#4d4d4d','#dedede']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(395px,150px) scale(0.7) ',
                    }
                },
                {
                    id:'ps',
                    position:{x:420,y:95},
                    data:{label:' Photoshop'},
                    style:{
                        transform:'translate(420px,95px) scale(0.7) ',
                        // background:'#cfe6fc',
                        borderRadius:'20px'
                    }
                    ,targetPosition:'left'
                },
                {
                    id:'psLv',
                    position:{x:465,y:115},
                    type:'progNode',
                    data:{
                        percent:9,
                        color:['#002952','#66acf2']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(465px,115px) scale(0.7) '
                    }
                },
                {
                    id:'ill',
                    position:{x:490,y:60},
                    data:{label:' Illustrator'},
                    style:{
                        transform:'translate(490px,60px) scale(0.7) ',
                        // background:'#fcecd2',
                        borderRadius:'20px'
                    }
                    ,targetPosition:'left'
                },
                {
                    id:'illLv',
                    position:{x:535,y:80},
                    type:'progNode',
                    data:{
                        percent:3,
                        color:['#3c240c','#f8a829']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(535px,80px) scale(0.7) '
                    }
                },
                {
                    id:'prem',
                    position:{x:490,y:20},
                    data:{label:' Premier'},
                    style:{
                        transform:'translate(490px,20px) scale(0.7) ',
                        // background:'#f8e6fc',
                        borderRadius:'20px'
                    }
                    ,targetPosition:'left',sourcePosition:'top'
                },
                {
                    id:'premLv',
                    position:{x:535,y:5},
                    type:'progNodeD',
                    data:{
                        percent:5,
                        color:['#330D3E','#E298F2']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(535px,5px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'prog',
                    position:{x:150,y:120},
                    data:{label:'👩‍💻 Programmin'}
                    ,targetPosition:'left',
                    sourcePosition:'bottom'
                    ,style:{
                        borderRadius:'10px'
                    }
                },
                {
                    id:'git',
                    position:{x:-30,y:190},
                    data:{label:'Git'},
                    style:{
                        width:'100px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px',
                    },
                    targetPosition:'right'
                },
                {
                    id:'gitLv',
                    position:{x:10,y:215},
                    type:'progNodeD',
                    data:{
                        percent:7.5,
                        color:['#F1502F','#3E2C00']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(10px,215px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'npm',
                    position:{x:100,y:220},
                    data:{label:'npm'},
                    style:{
                        width:'100px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'npmLv',
                    position:{x:140,y:245},
                    type:'progNodeD',
                    data:{
                        percent:6,
                        color:['#CC3534','#ededed']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(140px,245px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'pyt',
                    position:{x:220,y:220},
                    data:{label:'Python'},
                    style:{
                        width:'100px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'pyLv',
                    position:{x:260,y:245},
                    type:'progNodeD',
                    data:{
                        percent:3,
                        color:['#306998','#FFD43B']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(260px,245px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'front',
                    position:{x:330,y:190}
                    ,data:{label:'🌎 FrontEnd'},
                    style:{
                        width:'100px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    },
                    targetPosition:'left',sourcePosition:'right'
                },
                {
                    id: 'frontGroup',
                    type: 'output',
                    position: {
                        x: 650,
                        y: -50
                    },
                    data: null,
                    style: {
                        width: 200,
                        height: 220,
                        backgroundColor: 'rgba(240,240,240,0.25)',
                        borderRadius:'10px',
                    },
                },
                {
                    id:'html',
                    position:{x:10,y:10},
                    data:{label:'HTML'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'htmlLv',
                    position:{x:50,y:55},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:9,
                        color:['#e34c26','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(680px,-20px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'css',
                    position:{x:100,y:10},
                    data:{label:'CSS'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'cssLv',
                    position:{x:140,y:55},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:9,
                        color:['#2965f1','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(770px,-20px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'sass',
                    position:{x:10,y:60},
                    data:{label:'Sass'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'sassLv',
                    position:{x:50,y:105},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:9,
                        color:['#cc6699','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(680px,30px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'boot',
                    position:{x:100,y:60},
                    data:{label:'Bootstrap'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px',
                        fontSize:'10px'
                    }
                },
                {
                    id:'bootLv',
                    position:{x:140,y:105},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:9,
                        color:['#563d7c','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(770px,30px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'tail',
                    position:{x:10,y:110},
                    data:{label:'Tailwind'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'tailLv',
                    position:{x:50,y:155},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:6,
                        color:['#81d4fa','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(680px,80px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'js',
                    position:{x:100,y:110},
                    data:{label:'Javascipt'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px',
                        fontSize:'10px'
                    }
                },
                {
                    id:'jsLv',
                    position:{x:140,y:155},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:9,
                        color:['#fdd835','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(770px,80px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'jq',
                    position:{x:10,y:160},
                    data:{label:'JQuerry'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px'
                    }
                },
                {
                    id:'jqLv',
                    position:{x:50,y:205},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:7,
                        color:['#1565c0','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(680px,130px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                {
                    id:'re',
                    position:{x:100,y:160},
                    data:{label:'ReactJS'},
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    type:'input',
                    style:{
                        width:'80px',
                        height:'30px',
                        padding:'4px 2px',
                        borderRadius:'20px',
                        fontSize:'10px'
                    }
                },
                {
                    id:'reLv',
                    position:{x:140,y:205},
                    type:'progNodeD',
                    parentNode: 'frontGroup',
                    extent: 'parent',
                    data:{
                        percent:7.5,
                        color:['#4fc3f7','#ebebeb']
                    },
                    targetPosition:'left',
                    style:{
                        transform:'translate(770px,130px) scale(0.7) '
                    },targetPosition:'bottom'
                },
                
            ],
            edges:[
                {
                    id:'me-edu',
                    source:'me',
                    target:'edu',
                    animated: true,
                    style: { stroke: 'white' },
                },
                {
                    id:'edu-maj',
                    source:'edu',
                    target:'maj',
                    animated: false,
                    style: { stroke: 'white' },
                },
                {
                    id:'maj-azad',
                    source:'maj',
                    target:'azad',
                    animated: false,
                    style: { stroke: 'white' },
                },
                {
                    id:'me-other',
                    source:'me',
                    target:'other',
                    animated: true,
                    style: { stroke: 'white' },
                },
                {
                    id:'other-photo',
                    source:'other',
                    target:'photo',
                    animated: false,
                    style: { stroke: 'white' },
                },
                // {
                //     id:'photo-photoLv',
                //     source:'photo',
                //     target:'photoLv',
                //     animated: true,
                //     style: { stroke: 'white' },
                // },
                {
                    id:'other-ps',
                    source:'other',
                    target:'ps',
                    animated: false,
                    style: { stroke: 'white' },
                },
                // {
                //     id:'ps-psLv',
                //     source:'ps',
                //     target:'psLv',
                //     animated: true,
                //     style: { stroke: 'white' },
                // },
                {
                    id:'other-ill',
                    source:'other',
                    target:'ill',
                    animated: false,
                    style: { stroke: 'white' },
                },
                // {
                //     id:'ill-illLv',
                //     source:'ill',
                //     target:'illLv',
                //     animated: true,
                //     style: { stroke: 'white' },
                // },
                {
                    id:'other-prem',
                    source:'other',
                    target:'prem',
                    animated: false,
                    style: { stroke: 'white' },
                },
                // {
                //     id:'prem-premLv',
                //     source:'prem',
                //     target:'premLv',
                //     animated: true,
                //     style: { stroke: 'white' },
                // },
                {
                    id:'me-prog',
                    source:'me',
                    target:'prog',
                    animated: true,
                    style: { stroke: 'white' },
                },
                {
                    id:'prog-git',
                    source:'prog',
                    target:'git',
                    animated: false,
                    style: { stroke: 'white' },
                },
                {
                    id:'prog-npm',
                    source:'prog',
                    target:'npm',
                    animated: false,
                    style: { stroke: 'white' },
                },
                {
                    id:'prog-pyt',
                    source:'prog',
                    target:'pyt',
                    animated: false,
                    style: { stroke: 'white' },
                },
                {
                    id:'prog-front',
                    source:'prog',
                    target:'front',
                    animated: false,
                    style: { stroke: 'white' },
                },
                {
                    id:'front-frontGroup',
                    source:'front',
                    target:'frontGroup',
                    animated: true,
                    style: { stroke: 'white' },
                },
            ]
            ,Styles:{
                bgStyle:{
                    backgroundColor: 'rgb(25, 25, 25)'
                }
            },display:{
                mobile:true,
                desktop:false
            },
            nodeTypes:{progNode:ProgNode,progNodeD:ProgNodeD,meNode:MeNode},
            marqStyle:{
                height:'10vh',
                fontSize:'30px',
                borderTop:'2px solid white',
                boderBottom:'2px solid white'
            }
        }
    }
    
    render(){
        return(
            <>
                <div id="Skills" className="Section">
             <Marquee 
                    style={this.state.marqStyle}
                    speed={50}
                    gradient={false}
                    >
                    Skills <div className={'dot'}></div>
                    技能 <div className={'dot'}></div>
                    vaardigheid <div className={'dot'}></div>
                    умение<div className={'dot'}></div>
                    beceri <div className={'dot'}></div>
                    </Marquee>
                    <div id="skillsParent">
                        <ReactFlow 
                        nodes={this.state.nodes} 
                        edges={this.state.edges}
                        style={this.state.Styles.bgStyle}
                        nodeTypes={this.state.nodeTypes}
                        fitView>
                            <Background />
                            <Controls />
                        </ReactFlow>
                    </div>
                </div>
            </>
        )
    }
}