import React from "react";
import { Component } from "react";
import './App.css'
import Start from './sections/1.Start/Start'
import Header from './sections/2.Header/Header';
import Home from './sections/3.Home/Home'
import About from './sections/4.about/About'
import Skills from './sections/5.Skills/Skill'
import Works from './sections/6.Works/Works'
import Contact from './sections/7.Contact/Contact'

export default class main extends Component{
    constructor(){
        super()
        this.state={
            marqStyle:{
                height:'10vh',
                fontSize:'30px',
                borderTop:'2px solid white',
                boderBottom:'2px solid white'
            }
        }
    }

    componentDidMount(){
        let temp = document.getElementsByTagName('main')[0].clientHeight
        console.log(temp)
    }

    // Scroll = () => {

    // }

    render(){
        return(
            <>
                <main>
                    <Header></Header>
                    <Home></Home>
                    
                    <About></About>

                   

                    <Skills></Skills>

                    

                    <Works></Works>

                    
                    <Contact></Contact>


                    <Start></Start>

                </main>
            </>
        )
    }
}