import React from "react";
import { Component } from "react";
import './about.css'
import Marquee from "react-fast-marquee";



export default class About extends Component{
    constructor(){
        super()
        this.state = {
            marqStyle:{
                height:'10vh',
                fontSize:'30px',
                borderTop:'2px solid white',
                boderBottom:'2px solid white',
                position:'absolute',
                top:'0px'
            }
        }
    }
    componentDidMount(){

    }
    render(){
        return(
            <>
                <div id="Me" className="Section">
            <Marquee 
                    style={this.state.marqStyle}
                    speed={50}
                    gradient={false}
                    >
                    About <div className={'dot'}></div>
                    關於 <div className={'dot'}></div>
                    over <div className={'dot'}></div>
                    около<div className={'dot'}></div>
                    hakkında <div className={'dot'}></div>
                    </Marquee>
                    <div className="leftCircles">
                        <div className="CircleContainer">
                            <div className="circles"></div>
                        </div>
                    </div>

                    <div className="rightParent">
                        <p id="aboutMe">
                        <div className="brac" id="brac1">{(`{`)}</div>
                            <h2>About Me</h2><hr />Hi! I'm Arash Kheirollahi and im currently 21 years old <br />
                            at the momment I study bachelor's of computer engineering at Tehran Azad university, <br />
                            I love video games, cooking and writing poems without ryhms, I creat graphical content oftenly and post them on socila media.
                            <br />I started my front-End developer journey from hearing about<strong><a target='_blank' rel="dofollow" href="https://www.trainingsitedesign.ir"> Parnnian </a></strong>School from one of my friends ( Which I'm really thankful of them) <br />
                            I've met Mr. <strong><a target='_blank' href="https://www.instagram.com/parsa_ghorbanian_web/" rel="dofollow">Parsa Ghorbanian</a></strong> at Parnian, no doubts he is one of a kind and I'd like to thank him for all the work that he has done for us. <br />
                            Currently I develop web with <strong><a target='_blank' href="https://reactjs.org">React</a></strong> and aimimng to grow my progress and knowledge into different frameworks and technologies. <br />I believe nothing is undoable if you don't just quit with your first failure.
                        <div className="brac" id="brac2">{(`}`)}</div>
                        </p>
                        <a href="#Skills">Skill chart</a>
                    </div>
                </div>
            </>
        )
    }
}